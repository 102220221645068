
/* eslint-disable camelcase */
import React, { Component } from 'react';
// import {  Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import UpComingChallenges from '../ContentV3/UpComingChallenges';
import UpComingEvents from '../ContentV3/UpcomingEvents';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { BirthdayCard, WorkAnniversaryCard } from '../index'
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { StyledModal, StyledHeader, StyledBody, CloseIcon, TitleContainer, MiddleSection2, ImgWrap, ContentWrap, CustomMenuItem2, FieldTitle, RecogntionPopupWrapper, BorderLine} from './styles';
import TaskToCompleteV3 from '../TaskToCompleteV3';
import {
  SocialFeedsContainer, ContentContainer, InputFieldContainer, SideBarContainer, TileContainer, TitleContent, SideBarContent,
  StyledImage, BottomSection, LeftContent, NotificationDescription, NoComments, Layout, NoActivityFeeds, FeedsContainer, CommentSection, ScrollableFeedsContainer, AssessmentContainer, TipInformation, ButtonWrapper, PostWrapper, PhotoWrapper, RecognitionWrapper, LinkContainer, Link, LinkTab, PictureDiv,
} from '../SocialFeedsV2/styles';
import { PageTitle } from '../CreateEvent/styles';
import SingleFeed from '../SocialFeedsV2/SingleFeed';
import Comments from '../Comments';
import Loading from '../Loading';
import { imageErrorMessage, ImageUrl, imgPath } from '../../utils/constants';
import { checkImage, applyHighlights } from '../../utils/methods';
import {feedCloseIcon, LaunchEventIcon} from '../../utils/icons';
import Image from '../Image';
import { getUserProfileImage, addCommentReplyOnPost, addSocialInspiration, getCommentsOnPost, getUsersToShoutout, getSuggestionsList } from '../../redux/actions';
import SocialFeedTextarea from '../SocialFeedsV2/SocialFeedTextarea';
import SocialFeedTextareaV2 from '../SocialFeedsV2/SocialFeedTextareaV2';
import { HraIcon } from '../DailyTip/styles';
import SuggestionForYou from '../SuggestionForYou';
import { getListOfConnectedDevices, revokeDevice, getCompanyExemplifiesValues } from '../../redux/actions';
import { PeerRecognition } from "../../../mockData";
import {HeaderText} from '../PhotoAndPostFlow/styles';
import { withTranslation } from 'react-i18next';
import SocialFeedsEmojiPicker from '../SocialFeedsEmojiPicker';
import SocialFeedTextAreaRecogniton from '../SocialFeedsV2/socialFeedTextAreaRecognition';
import { Dropdown } from 'react-bootstrap';
import {NewActivityDropdownCommon} from '../common/CommonDropDown/style';
import {PostButtonWrapper, PostButtonCard} from '../PhotoAndPostFlow/styles';

const iSiOS = !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
class Peer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
      // showModal: '',
      inspirationQuote: '',
      showCommentsFor: '',
      pageNumber: 1,
      showEditDeletePopup: '',
      editDetails: '',
      editAndDelete: false,
      isUploadedImage: false,
      refreshFileReader: false,
      mentionedUsers: [],
      shoutoutType: 0,
      users: [],
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      imgSrcArray: [],

      taggedUser: '',
      postDisabled: true,
      exemplifyID: null,
      activeLink: 0,
      showConnectDevice: false,
      showPhotoPostModal: false,
      fields: PeerRecognition.fields,
      exemplifiesValues: undefined,
      trigger: {},
      showSuggestor: false,
      top: null,
      left: null,
      employee: [],
      currentSelection: 0,
      text: null,
      exemplifiesData: [],
      profileImageArray: [],
      description: '',
      dropdown: true,
      displayEmoji: false,
      ShowEmojiContainer: false,
      position: { start: 0, end: 0 },
    };
    this.myref = React.createRef();
    this.emojiref = React.createRef();
  }

  handleClick = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !isNull(this.myref.current) && !isUndefined(this.myref.current) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({ showConnectDevice: false });
    }
  };

  handleEmojiClick = (event) => {
    if (!isNull(this.emojiref) && !isUndefined(this.emojiref) && !(this.emojiref && this.emojiref.current && this.emojiref.current.contains(event.target))) {
      // this.setState({displayEmoji: false});
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", this.handleEmojiClick);
    const { profileImage, fetchUserProfileImage, getSuggestionsList, fetchConnectedDevices, getCompanyExemplifiesValues } = this.props;
    getSuggestionsList();
    if (!profileImage) {
      fetchUserProfileImage();
    }
    fetchConnectedDevices();
    getCompanyExemplifiesValues();
    this.checkDevice();
    this.fetchUsers();
  }

  checkDevice = () => {
    let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if (isAndroid) {
      let triggerObj = {};
      triggerObj.keyCode = 229;
      this.setState({
        trigger: triggerObj
      });
    } else {
      let triggerObj = {};
      triggerObj.keyCode = 50;
      triggerObj.shiftKey = !iSiOS;
      this.setState({
        trigger: triggerObj
      })
    }
  };

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmoji: !prev.displayEmoji
    }))
  }

  hideEmojiPicker = () => {
    this.setState({
      displayEmoji: false
    })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", this.handleEmojiClick);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exemplifiesValues !== this.props.exemplifiesValues) {
      this.setState({
        exemplifiesData: this.props.exemplifiesValues
      })
    }
  }
  removeImage = (src) => {
    let arrayy = this.state.imgSrcArray.filter((item) => item !== src)
    this.setState({
      imgSrcArray: arrayy,
      refreshFileReader: false
    });
  }
  displayDeviceModal = () => {
    this.props.fetchConnectedDevices();
    this.setState({ showDeviceModal: true })
  };

  openEditAndDelete = () => {
    this.setState({
      editAndDelete: !this.state.editAndDelete
    })
  };

  changeEditDeletePopup = (value, feed) => {
    this.setState({ editDetails: feed }, () => {
      this.setState({ showEditDeletePopup: value });
    });
  };

  onHideEditDeletePopup = () => {
    this.setState({
      showEditDeletePopup: ''
    }, () => {
      this.openEditAndDelete();
    })
  };

  storeFetchedEmployee = () => {
    this.setState({
      employee: this.props.employeeData
    });
  };

  getCommentsForPost = (id) => {
    const { fetchCommentsOnPost } = this.props;
    this.setState({
      showCommentsFor: id
    });
    fetchCommentsOnPost(id);
  };

  onEmojiSelect = (emoji, id, native, skin) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.addInspirationV2(native);
  }


  onScroll = (e) => {
    const { pageNumber } = this.state;
    const { count, feeds } = this.props;
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom && feeds?.length < count) {
      this.setState({
        pageNumber: pageNumber + 1
      }, () => {
        this.props.loadMoreFeeds(this.state.pageNumber);
      });
    }
  };

  renderFeeds = (feeds) => {
    const { showCommentsFor, editAndDelete } = this.state;
    const { isLoading, comments, replySuccess, postReplyOnComment, profileImage, history, enableTrigger } = this.props;
    return (
      <div>
        {
          feeds && feeds.length > 0 ?
            <ScrollableFeedsContainer onScroll={this.onScroll}>
              {
                feeds.map((feed, index) => (
                  <FeedsContainer key={index}>
                    <SingleFeed
                      feed={feed}
                      description={feed.description}
                      taggedUsers={feed.shoutout_user_name}
                      getCommentsForPost={this.getCommentsForPost}
                      changeEditDeletePopup={this.changeEditDeletePopup}
                      editAndDelete={editAndDelete}
                      openEditAndDelete={this.openEditAndDelete}
                      profileImage={profileImage}
                      updationList="response"
                      commentCount={feed.comment_count}
                      history={history}
                      enableTrigger={enableTrigger}
                    />
                    {showCommentsFor === feed.id &&
                      (isLoading ? <Loading isHorizontal /> :
                        (isEmpty(comments) ? <NoComments>No Comments to display</NoComments> :
                          <Comments
                            comments={comments}
                            postReply={postReplyOnComment}
                            replySuccess={replySuccess}
                            id={feed.id}
                            history={history}
                            enableTrigger={enableTrigger}
                          />))}
                  </FeedsContainer>
                ))
              }
            </ScrollableFeedsContainer> : <NoComments>No posts in this section</NoComments>

        }
      </div>
    )
  };

  addDescription = (e) => {
    const text = e.target.value;
  
    // Allow user to remove characters if they exceed the limit
    if (text.length <= 500) {
      let position = this.getPosition(e.target);
      this.setState({
        position: position,
        description: text,
      });
    } else {
      // Prevent adding more characters beyond the limit
      let position = this.getPosition(e.target);
      this.setState({
        position: position,
        description: text.slice(0, 500),
      });
    }
  };

  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  addInspirationV2 = (e) => {
    const { description, position } = this.state;
    const newText = description.slice(0, position.start) + e + description.slice(position.end);
  
    if (newText.length > 500) {
      // Prevent adding more characters beyond the limit
      const allowedText = e.slice(0, 500 - description.length);
      const finalText = description.slice(0, position.start) + allowedText + description.slice(position.end);
      this.setState({
        position: { start: position.start + allowedText.length, end: position.end + allowedText.length },
        description: finalText,
      });
    } else {
      this.setState({
        position: { start: position.start + e.length, end: position.end + e.length },
        description: newText,
      });
    }
  };

  updateText = (text, userId, taggedUserName, bool, profileImage) => {
    const { userIdArray, users, taggedUser, profileImageArray } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let images = [...profileImageArray, profileImage];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      inspirationQuote: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool,
      profileImageArray: images
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplify_id) => {

    this.setState({
      mentionedUsers: mentionedUsers,
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplify_id ? exemplify_id : null,
      exemplifyID: exemplify_id ? exemplify_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: [],
      profileImageArray: []
    });
  };
  onRemoveSingleUser = (index) => {
    const { profileImageArray, taggedUser } = this.state;
    let taggedUserArray = taggedUser.split(',');
    taggedUserArray.splice(index, 1);
    profileImageArray.splice(index, 1);
    let taggedUserJoin = taggedUserArray && taggedUserArray.length > 0 ? taggedUserArray.join(',') : '';
    let userlength = profileImageArray.length === 0 && taggedUserArray.length === 0;
    if (userlength) {
      this.setState({
        taggedUser: '',
        showGivenShoutout: false,
        userIdArray: [],
        shoutoutImage: null,
        shoutoutText: null,
        users: [],
        profileImageArray: []
      });
    } else {
      this.setState({
        taggedUser: taggedUserJoin,
        profileImageArray: profileImageArray
      });
    }
  };

  renderInputField = (placeholder, UpdateStatus, isCommentOrReply) => (
    <InputFieldContainer UpdateStatus={UpdateStatus}>
      <PictureDiv>
        <img src="../../../public/images/social/userIcon.png" />
      </PictureDiv>
      <SocialFeedTextarea
        userCompany={this.props.userCompany}
        addInspiration={this.addInspiration}
        inspirationQuote={this.state.inspirationQuote}
        updateText={this.updateText}
        users={this.state.users}
        userIdArray={this.state.userIdArray}
        placeholder={placeholder}
        showGivenShoutout={this.state.showGivenShoutout}
        updateShoutoutArray={this.updateShoutoutArray}
        emptyShoutoutArray={this.emptyShoutoutArray}
        onRemoveShoutout={this.onRemoveShoutout}
        isCommentOrReply={isCommentOrReply}
        shoutoutText={this.state.shoutoutText}
        shoutoutImage={this.state.shoutoutImage}
        taggedUser={this.state.taggedUser}
        onRemoveUser={this.onRemoveUser}
        displayEmojiPicker={true}
        showEmojiPicker={this.showEmojiPicker}
        displayEmoji={this.state.displayEmoji}
        enableTrigger={this.props.enableTrigger}
        emojiref={this.emojiref}
        ShowEmojiContainer={this.state.ShowEmojiContainer}
        hideEmojiPicker={this.hideEmojiPicker}
      />
    </InputFieldContainer>
  );

  renderDidYouKnowTip = () => (
    <TileContainer>
      <TitleContent>
        <i className="fas fa-question" />
        <span>&nbsp;Did you know?</span>
      </TitleContent>
      <SideBarContent>
        <span>Did you know that improving your balance can improve your athletic ability?</span>
      </SideBarContent>
    </TileContainer>
  );

  fetchUsers = () => {
    const { userCompany, fetchUsersToShoutout } = this.props;
    let obj = {};
    obj['company_id'] = userCompany.id;
    obj['name'] = this.state.text;
    obj['limit'] = 6;
    if (!_.isNull(userCompany.id))
      fetchUsersToShoutout(obj, this.storeFetchedEmployee);
  };

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest } = this.props;
    acceptOrRejectFriendRequest({ request_id: id, status: status });
  };

  renderActivityFeeds = (feeds) => (
    <TileContainer>
      <TitleContent>
        <i className="fas fa-list" />
        <span>Activity Feed:</span>
        <span><span>{feeds.length}</span>&nbsp;new notifications</span>
      </TitleContent>
      {feeds.length > 0 ? feeds.map((feed, index) => (
        <SideBarContent key={index} notificationSection >
          <StyledImage left>
            <div className="middle">
              <Image image={feed.friend_image} alt={feed.friend_name} />
            </div>
            <NotificationDescription>
              {feed.friend_name} <span className=" lowerCase">{feed.action_type + ' with '}</span>
              {feed.fof_name}
            </NotificationDescription>
          </StyledImage>
        </SideBarContent>
      )) : <NoActivityFeeds>No activities to show</NoActivityFeeds>}
    </TileContainer>
  );

  onHide = () => {
    this.setState({
      showModal: '',
      imgSrc: ""
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUploadedImage: true
        })
      }
      else {
        this.setState({
          isUploadedImage: false
        })
      }
    }
  }

  togglePostButton = (value, image) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '' || image !== '') {
      return false;
    }
    return true;
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";</span>");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  addPost = () => {
    const { inspirationQuote, mentionedUsers, users, userIdArray, taggedUser, exemplifyID, description } = this.state;
    const { addSocialInspiration, shoutOut, onClose, showRecognitionSingle } = this.props;
    let inspirationData = {
      image: this.state.imgSrcArray[0],
      post_images: this.state.imgSrcArray,
      description: inspirationQuote === '' ? this.toCodePoints(description) : this.toCodePoints(inspirationQuote),
      shoutout: {
        exemplifies: userIdArray
      },
      // mentioned_user: userIdArray
    };
    if (!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray)) {
      inspirationData.shoutout_user_name = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
      inspirationData.exemplify_id = exemplifyID
    } else if (shoutOut && shoutOut.core_value) {
      inspirationData.shoutout_user_name = `<span id="${shoutOut.uid}">${shoutOut.name}</span> - ${shoutOut.core_value}`;
      inspirationData.exemplify_id = shoutOut.examplifyId;
      inspirationData.shoutout.exemplifies = [shoutOut.uid];
    }
    if(showRecognitionSingle){
      inspirationData.exemplify_id = shoutOut.examplifyId;
    }
    addSocialInspiration(inspirationData);
    this.setState({
      inspirationQuote: '',
      imgSrc: "",
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      shoutoutType: 0,
    })
    this.onHide();
    onClose();
  };

  renderAssessmentPanel = () => {
    const { history } = this.props;
    return (
      <AssessmentContainer>
        <TipInformation onClick={() => history.push('/wellness-assessment/medical')}>
          <HraIcon><img src={'/public/images/HRA_icon.svg'} /></HraIcon>
          <span>TAKE THE HEALTH RISK ASSESSMENT</span>
        </TipInformation>
      </AssessmentContainer>
    );
  };

  handleTabSwitch = (index) => {
    this.setState({
      activeLink: index
    })
  }

  toggleConnectDevices = () => {
    this.setState((prev) => ({
      showConnectDevice: !prev.showConnectDevice
    }));
  };

  handlePhotoPostModal = () => {
    this.setState((prev) => ({
      showPhotoPostModal: !prev.showPhotoPostModal
    }));
  };
  renderView = () => {
    // const { inspirationQuote, userIdArray, shoutoutText } = this.state;
    const { feeds, history, suggestionList, user, t } = this.props;
    let Tabs = [
      {
        tabName: "Company"
      },
      {
        tabName: "Buddies"
      },
      {
        tabName: "Recognition"
      }
    ];
    return (
      <Layout>
        <PageTitle>Update Status</PageTitle>
        <ContentContainer>
          <SocialFeedsContainer>
            <CommentSection>
              {this.renderInputField("What's on your mind?", "UpdateStatus")}
              <ButtonWrapper>
                <PostWrapper onClick={() => this.handlePhotoPostModal()}>
                  <img src="../../../public/images/social/post.svg" />
                  <p>{this.props.t("Post")}</p>
                </PostWrapper>
                <PhotoWrapper>
                  <img src="../../../public/images/social/photo.svg" />
                  <p>{this.props.t("Photo")}</p>
                </PhotoWrapper>
                <RecognitionWrapper>
                  <img src="../../../public/images/social/peer_recognition.svg" />
                  <p>{t("Peer Recognition")}</p>
                </RecognitionWrapper>
              </ButtonWrapper>
            </CommentSection>
            <LinkContainer>
              <Link>
                {
                  Tabs.map((data, index) => (
                    <LinkTab key={index} active={this.state.activeLink === index} onClick={() => this.handleTabSwitch(index)} >
                      {this.props.t(data.tabName)}
                    </LinkTab>
                  ))
                }
              </Link>
            </LinkContainer>
            {this.renderFeeds(feeds)}
          </SocialFeedsContainer>
          <SideBarContainer>
            <BirthdayCard />
            <WorkAnniversaryCard />
            <UpComingChallenges history={history} />
            <UpComingEvents history={history} />
            <TaskToCompleteV3
              history={history}
              user={user}
              displayDeviceModal={this.toggleConnectDevices}
            />
            <SuggestionForYou suggestionList={suggestionList} history={history} />
          </SideBarContainer>
        </ContentContainer>
      </Layout>
    );
  };

  toggleSuggestor(metaInformation) {
    const { hookType, cursor } = metaInformation;
    if (hookType === "start") {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height,
        startPosition: iSiOS ? (this.textarea.value.length === 0 ? 1 : this.textarea.value.length) : cursor.selectionStart
      });
    }
    if (hookType === "cancel") {
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }

  handleSelect = (key) => {
    this.props.setActiveTab(key);
  };

  onSelect = (key, item) => {
    this.setState({
      [key]: item.core_value,
      exemplifyID: item.id,
      dropdown: !this.state.dropdown
    });
  };


  image = () => (
    this.state.imgSrcArray.map((src, i) => (
      (
        <div className='imageDiv' key={i} >
          <img alt='img' width='150px' height='150px' src={src} />
          <span className='remove' onClick={() => this.removeImage(src)} style={{background:"white", borderRadius:"50%", height:"24px", width:"24px", justifyContent:"center", alignItems:"center", display:"flex"}}><img width='18px' height="18px" src={ImageUrl + "/social-feed/imageCross.png"}></img></span>
        </div>
      )
    )));

  addInspiration = (e) => {
    this.setState({
      inspirationQuote: e.target.value,
    });
  };

  handleInput(metaInformation) {
    if (metaInformation.text.includes('@')) {
      this.setState({
        text: '',
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      }, () => this.fetchUsers());
    }
    else {
      this.setState({
        text: metaInformation.text,
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      }, () => this.fetchUsers());
    }

  }

  handleKey(event) {
    const { which } = event;
    const { currentSelection, employee } = this.state;

    if (_.isNaN(currentSelection)) {
      event.preventDefault();
      this.setState({
        currentSelection: 0
      });
    }

    if (which === 40) {
      event.preventDefault();

      this.setState({
        currentSelection: (currentSelection + 1) % employee.length,
      });
    }

    if (which === 38) {
      event.preventDefault();
      if (currentSelection - 1 < 0)
        this.setState({
          currentSelection: employee.length - 1,
        })
      else
        this.setState({
          currentSelection: (currentSelection - 1) % employee.length,
        });
    }

    if (which === 13 && this.state.showSuggestor && employee[currentSelection]) {
      event.preventDefault();

      const { employee, currentSelection, textLength, startPosition } = this.state;
      const { inspirationQuote } = this.props;
      const taggedUser = employee[currentSelection].fullname.trim();
      const userId = employee[currentSelection].uid;
      const profileImage = employee[currentSelection].profileImage;
      const newText = `${inspirationQuote.slice(0, startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null,
        textareaValue: newText,
        currentSelection: 0,
        taggedUser: taggedUser
      }, () => {
        this.updateText(newText, userId, taggedUser, true, profileImage)
      });

      // this.endHandler();
    }
  }

  handleClick2 = () => {
    const { employee, currentSelection, textLength, startPosition } = this.state;
    const { inspirationQuote } = this.props;
    let userArray = [...this.props.users];
    const taggedUser = employee[currentSelection].fullname.trim();
    if (userArray.indexOf(taggedUser) === -1)
      userArray.push(taggedUser);
    const userId = employee[currentSelection].uid;
    const newText = `${inspirationQuote.slice(0, startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: null,
      startPosition: null,
      textareaValue: newText,
      currentSelection: 0,
      taggedUser: taggedUser
    }, () => {
      this.updateText(newText, userId, taggedUser, true);
    });

    // this.endHandler();
  }

  handleMouseHover = (index) => {
    this.setState({
      currentSelection: index,
    });
  }
  handleDropdownImage = () => {
    this.setState({ dropdown: !this.state.dropdown })
  }
  onChange = (e) => {
    let files = e.target.files;
    // let length = files.length;

    if(files && files.length > 0)
    {
      for (let i = 0; i < Math.min(files.length, 5); i++) {
        if (checkImage(files)) {
          if ((files[i]?.size / 1000000) <= 20) {
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onloadend = () => {
              this.setState({
                imgSrcArray: [...this.state.imgSrcArray, reader.result],
                refreshFileReader: true
              })
            };
          } else {
            toast.error('Please select image file less than 20MB');
            document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
          }
        } else {
          toast.error(imageErrorMessage);
          document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
        }
      }
    }
  };

  render() {
    const { showModal, onClose, shoutOut, t, showRecognitionSingle, profileImage } = this.props;
    return (
      <div>
        <StyledModal show={showModal} onHide={()=>onClose()} width="800px">
          <StyledHeader background border>
            {<HeaderText color>{shoutOut.core_value == "Happy Birthday" ? this.props.t("Birthday Wish") : shoutOut.core_value == "Work Anniversary" ? this.props.t("Annivarsary Wish ") : this.props.t("Give a Recognition")}</HeaderText>}
            <CloseIcon onClick={onClose}>
              {feedCloseIcon()}
            </CloseIcon>
          </StyledHeader>
          <StyledBody active={showRecognitionSingle && this.state.profileImageArray.length >0?true:  (this.state.exemplifyID && this.state.userIdArray &&  this.state.userIdArray.length>0)||shoutOut.core_value == "Happy Birthday"||shoutOut.core_value == "Work Anniversary"?true:false} background>
            <RecogntionPopupWrapper >
              <div className='user-details'>
                <div className='profile'>
                  <img src={`${ImageUrl}/${profileImage}`} />
                </div>
                <div className='name'>{this.props.user?.fname} {this.props.user?.lname}</div>
              </div>
              {
                shoutOut && shoutOut.core_value ? (
                  this.state.fields.map((field) => (
                    <TitleContainer key={field.name}>
                      <FieldTitle>{field.label}</FieldTitle>
                      <CustomMenuItem2 mt={true}>
                        <ImgWrap margin={"0 5px 0 15px"}>
                          <img src={shoutOut.core_value.includes("Birthday") || shoutOut.core_value.includes("Work Anniversary") ?`${imgPath}/${shoutOut.image}`:`${ImageUrl}/${shoutOut.image}`} />
                        </ImgWrap>
                        <ContentWrap>
                          {shoutOut.core_value.includes("Work") ? `Happy ${shoutOut.core_value}` : shoutOut.core_value}
                        </ContentWrap>
                      </CustomMenuItem2>
                    </TitleContainer>
                  )
                  )
                ) :
                  this.state.fields.map((field) => {
                    if (field.type === 'drop-down') {
                      let newTitle = field.placeholder;
                      if (this.props[field.key] && this.state.exemplifyID) {
                        const selectedCategory = this.props[field.key].filter((category) => category.id === this.state.exemplifyID);
                        newTitle = selectedCategory ? (selectedCategory[0] && selectedCategory[0].core_value) : field.placeholder;
                      }
                      return (
                        <TitleContainer key={field.name} paddingV="0px !important" scroll={this.state.exemplifiesData.length>6?"hidden scroll":"hidden"} heightV={this.state.exemplifiesData.length>6?"240px":"auto"}>
                          <FieldTitle>
                            {this.props.t("Add Recognition")}
                            <span className="important">*</span>
                          </FieldTitle>
  
                          <NewActivityDropdownCommon
                            onSelect={(index, /*key*/) => {
                              this.onSelect(this.state.exemplifiesData, this.state.exemplifiesData[index]);
                            }}
                            margin="0px"
                          >
                            <Dropdown.Toggle>
                              <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:newTitle? "#005C87":"#005C874D",fontFamily:"Rubik-regular"}}>
                                <div style={{color:newTitle!="Select Recognition"? "#005C87":"#005C874D"}}>{newTitle?newTitle:"Select Option"}</div>
                                {<div>        
                                  <img
                                    alt="image"
                                    src="/public/images/teamDownArrow.svg"
                                  />
                                </div>}
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.exemplifiesData.map((item, index) => (
                                <Dropdown.Item 
                                  eventKey={index}
                                  key={index}
                                >
                                  {this.props.t(item.core_value)}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </NewActivityDropdownCommon>
                        </TitleContainer>
                      );
                    }
                  })
  
              }
              <BorderLine/>
              <MiddleSection2 border={'none'}>
                <FieldTitle>
                  {t("Who would you like to recognize?")}<span className="important">*</span><span className='subtext'>{" (You can enter the names of up to 10 individuals)"}</span>
                </FieldTitle>
                {showRecognitionSingle?<SocialFeedTextAreaRecogniton
                  userCompany={this.props.userCompany}
                  addInspiration={this.addInspiration}
                  inspirationQuote={this.state.inspirationQuote}
                  updateText={this.updateText}
                  users={this.state.users}
                  userIdArray={this.state.userIdArray}
                  placeholder={t("Person you want to recognize enter initialis")}
                  showGivenShoutout={this.state.showGivenShoutout}
                  updateShoutoutArray={this.updateShoutoutArray}
                  emptyShoutoutArray={this.emptyShoutoutArray}
                  onRemoveShoutout={this.onRemoveShoutout}
                  shoutoutText={this.state.shoutoutText}
                  shoutoutImage={this.state.shoutoutImage}
                  taggedUser={this.state.taggedUser}
                  onRemoveUser={this.onRemoveUser}
                  enableTrigger={this.props.enableTrigger}
                  shoutOutData={shoutOut}
                  wishesAndAnni={!isEmpty(shoutOut) ? 1 : 0}
                  profileImageArray={this.state.profileImageArray}
                  peerRecognition={1}
                  onRemoveSingleUser={this.onRemoveSingleUser}
                />:<SocialFeedTextareaV2
                  userCompany={this.props.userCompany}
                  addInspiration={this.addInspiration}
                  inspirationQuote={this.state.inspirationQuote}
                  updateText={this.updateText}
                  users={this.state.users}
                  userIdArray={this.state.userIdArray}
                  placeholder={t("Enter names")}
                  showGivenShoutout={this.state.showGivenShoutout}
                  updateShoutoutArray={this.updateShoutoutArray}
                  emptyShoutoutArray={this.emptyShoutoutArray}
                  onRemoveShoutout={this.onRemoveShoutout}
                  shoutoutText={this.state.shoutoutText}
                  shoutoutImage={this.state.shoutoutImage}
                  taggedUser={this.state.taggedUser}
                  onRemoveUser={this.onRemoveUser}
                  enableTrigger={this.props.enableTrigger}
                  shoutOutData={shoutOut}
                  wishesAndAnni={!isEmpty(shoutOut) ? 1 : 0}
                  profileImageArray={this.state.profileImageArray}
                  peerRecognition={1}
                  onRemoveSingleUser={this.onRemoveSingleUser}
                />}
              </MiddleSection2>
              <BorderLine/>
              <BottomSection>
                <LeftContent>
                  <div>
                    <textarea
                      placeholder={t("Write here.")}
                      rows="3"
                      name=""
                      onChange={(e) => this.addDescription(e)}
                      value={this.state.description}
                      data-gramm_editor="false"
                      className="CustomTextAreaField"
                      onScroll={this.onScroll}
                      spellCheck="false"
                      ref={(node) => {
                        this.textarea = node;
                        if (this.textarea) {
                          this.textarea.style.height = 'inherit';
                          this.textarea.style.height = `${this.textarea.scrollHeight}px`;
                        }
                      }}
                      onInput={(e) => {
                        e.target.style.height = 'inherit';
                        e.target.style.height = `${e.target.scrollHeight}px`;
                      }}
                      onClick={(e) => this.addDescription(e)}
                    />
                  </div>
                </LeftContent>
              </BottomSection>
            </RecogntionPopupWrapper >
            {showRecognitionSingle?<PostButtonWrapper margin="1">
              <div className='emojiIcon' onClick={this.showEmojiPicker}>
                <img src={`${ImageUrl}/${"social-feed/emojiicon.svg"}`} />
              </div>
              <div className='post-button'>
                <PostButtonCard disable={showRecognitionSingle && this.state.profileImageArray.length >0?true:  (this.state.exemplifyID && this.state.userIdArray &&  this.state.userIdArray.length>0)||shoutOut.core_value == "Happy Birthday"||shoutOut.core_value == "Work Anniversary"?false:true} onClick={this.state.profileImageArray.length>0? this.addPost:null}>
                  <span>Post</span>
                  {LaunchEventIcon()}
                </PostButtonCard>
              </div>
              <div className='text-count'>
                {this.state.description?.length}/500
              </div>
            </PostButtonWrapper>:
              <PostButtonWrapper margin="1">
                <div className='emojiIcon' onClick={this.showEmojiPicker}>
                  <img src={`${ImageUrl}/${"social-feed/emojiicon.svg"}`} />
                </div>
                <div className='post-button'>
                  <PostButtonCard disable={showRecognitionSingle && this.state.profileImageArray.length >0?true:  (this.state.exemplifyID && this.state.userIdArray &&  this.state.userIdArray.length>0)||shoutOut.core_value == "Happy Birthday"||shoutOut.core_value == "Work Anniversary"?false:true} onClick={(this.state.exemplifyID && this.state.userIdArray &&  this.state.userIdArray.length>0)||shoutOut.core_value == "Happy Birthday"||shoutOut.core_value == "Work Anniversary"? this.addPost:''}>
                    <span>Post</span>
                    {LaunchEventIcon()}
                  </PostButtonCard>
                </div>
                <div className='text-count'>
                  {this.state.description?.length}/500
                </div>
              </PostButtonWrapper>}
          </StyledBody>
          {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}
        </StyledModal>
      </div>
    )
  }
}

Peer.propTypes = {
  feeds: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  addSocialInspiration: PropTypes.func.isRequired,
  friendRequests: PropTypes.array.isRequired,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activityFeeds: PropTypes.array.isRequired,
  comments: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  fetchCommentsOnPost: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  loadMoreFeeds: PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object,
  iswbaTaken: PropTypes.bool.isRequired,
  profileImage: PropTypes.string,
  fetchUserProfileImage: PropTypes.func,
  count: PropTypes.number,
  role: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  fetchUsersToShoutout: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  employeeData: PropTypes.array,
  enableTrigger: PropTypes.bool,
  userPermissions: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  suggestionList: PropTypes.array,
  getSuggestionsList: PropTypes.func,
  user: PropTypes.object,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func.isRequired,
  devicesDisconnected: PropTypes.array,
  fetchConnectedDevices: PropTypes.func.isRequired,
  dailyTip: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  getCompanyExemplifiesValues: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  inspirationQuote: PropTypes.string,
  users: PropTypes.array,
  shoutOut: PropTypes.object,
  t: PropTypes.func,
  showRecognitionSingle: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoading: state.social.isLoading,
  comments: state.social.comments,
  replySuccess: state.social.replySuccess,
  profileImage: state.profileData.profileImage,
  count: state.social.count,
  employeeData: state.peopleHome.employeeData,
  userCompany: state.profileData.userCompany,
  userPermissions: state.profileData.userPermissions,
  suggestionList: state.social.suggestionList,
  devices: state.wellnessDashboard.devices,
  devicesDisconnected: state.profileData.devicesDisconnected,
  dailyTip: state.profileData.dailyTip,
  exemplifiesValues: state.social.exemplifiesValues,
  user: state.profileData.user
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  addSocialInspiration: (inspirationData) => dispatch(addSocialInspiration(inspirationData)),
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  postReplyOnComment: (commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image) => dispatch(addCommentReplyOnPost(commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
  fetchConnectedDevices: () => dispatch(getListOfConnectedDevices()),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Peer));